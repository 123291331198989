import { z } from 'zod';

const optionalStringOrNumber = z.union([
  z.string(),
  z.number(),
  z.null(),
]).optional;

const optionalString = z.string().nullable().optional;

export const customContextSchema = z.object({
  env: z.object({
    ANNUAL_PLANS_ENABLED: z.boolean(),
    APP_COMMIT: optionalString(),
    APP_VERSION: optionalString(),
    APPSIGNAL_ENABLED: z.boolean(),
    APPSIGNAL_FALLBACK: z.boolean(),
    APPSIGNAL_JS_API_KEY: z.string(),
    APPSIGNAL_JS_ERROR_LOG_EXPIRATION_SECONDS: z.number(),
    APPSIGNAL_JS_IGNORE_ENABLED: z.boolean(),
    APPSIGNAL_JS_WINDOW_EVENTS: z.boolean(),
    TELEMETRY_USER: z.object({
      id: optionalStringOrNumber(),
      email: optionalString(),
      name: optionalString(),
      teamId: optionalStringOrNumber(),
      accountId: optionalStringOrNumber(),
      participantId: optionalStringOrNumber(),
      userId: optionalStringOrNumber(),
    }),
    BILLING_PAGE_FREE_TRIAL_CONTACT_URL: z.string(),
    BILLING_PAGE_PAID_SUB_CONTACT_URL: z.string(),
    CHILIPIPER_TEAMS_PLANS_INBOUND_ROUTER_KEY: z.string(),
    DEBUG_JAVASCRIPT_ERRORS: z.boolean(),
    DROPBOX_SIGN_CLIENT_ID: z.string(),
    DROPBOX_SIGN_SKIP_DOMAIN_VERIFICATION: z.boolean(),
    DROPBOX_SIGN_LIVE_MODE: z.boolean(),
    GOOGLE_BROWSER_API_KEY: z.string(),
    HUB_CONTACT_LIMIT: z.number(),
    CLOUDFLARE_TURNSTILE_CAPTCHA_ENABLED: z.boolean(),
    CLOUDFLARE_TURNSTILE_CAPTCHA_SITE_KEY: z.string(),
    INCENTIVES_SURVEY_URL: optionalString(),
    PAGE_THEMES_ENABLED: z.boolean(),
    PARTICIPANT_AVAILABILITY_PROPOSAL_BUFFER_HOURS: optionalStringOrNumber(),
    PARTICIPANT_AVAILABILITY_PROPOSAL_MAX_COUNT: optionalStringOrNumber(),
    PARTICIPANT_PROJECT_REFERRAL_INCENTIVE: optionalString(),
    PARTICIPANT_QNR_SIGNUP_BUFFER: z.number(),
    POPULATION_SEARCH_LIMIT: z.number(),
    PROJECT_BILLING_ACCOUNT_SPLIT: z.boolean(),
    PROJECT_BUILDER_LOOKBACK_URL: optionalString(),
    PROJECT_RECRUITMENT_MULTIPLIER: z.number(),
    PUBLIC_POPULATION_ID: z.number(),
    RECRUITMENT_SOURCE_REPORTS_URL: optionalString(),
    SITE_BASE_URL: z.string(),
    STRIPE_PUBLIC_KEY: z.string(),
    SUBSCRIPTION_SELF_SERVE_ENABLED: z.boolean(),
    WORKSPACE_EMAIL_STATS_ENABLED: z.boolean(),
  }),
  location: z.string(),
  request: z.object({
    csrfToken: z.string(),
    csrfParam: z.string(),
  }),
  uiState: z.object({
    isCustomLinkWithRedirectEnabled: z.boolean(),
    isKTPB: z.boolean(),
    isNylasMicrosoftEnabled: z.boolean(),
    isRxFunnelEnabled: z.boolean(),
    isShortlistParticipantsEnabled: z.boolean(),
    toggleLocationTypeEnabled: z.boolean(),
    toggleActivityTypeEnabled: z.boolean(),
    toggleProfessionalTargetingEnabled: z.boolean(),
    toggleSourceTypeEnabled: z.boolean(),
  }),
  user: z.object({}),
})
