export function parseQueryString(queryString: string) {
  // Remove leading '?' if present
  const query = queryString.startsWith('?') ? queryString.slice(1) : queryString;

  // Parse the query string
  const parsedParams: Record<string, any> = {};

  // Use URLSearchParams to parse the query string
  const params = new URLSearchParams(query);

  params.forEach((value, key) => {
    // Decode keys and values
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value);

    // Handle array-like keys like filter[] and attributeOptionIds
    if (decodedKey.startsWith('filter[')) {
      const match = decodedKey.match(/\[([a-zA-Z0-9]+)\]$/); // Extract the inner key like "attributeOptionIds"

      if (match) {
        const filterKey = match[1];

        // Initialize filter array if not present
        if (!parsedParams.filter) {
          parsedParams.filter = [];
        }

        // Handle comma-separated values (e.g., "1,2")
        const values = decodedValue.split(','); // Keep values as strings

        // Join values back into a comma-separated string and push as an object
        if (Array.isArray(parsedParams.filter)) {
          parsedParams.filter.push({ [filterKey]: values.join(',') });
        }
      }
    } else {
      // Handle other key-value pairs like pagination
      parsedParams[decodedKey] = decodedValue;
    }
  });

  return parsedParams;
}
