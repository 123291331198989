// https://developers.cloudflare.com/turnstile/troubleshooting/client-side-errors/error-codes/
const CLOUDFLARE_TURNSTILE_ERROR_CODES = [
  106010, // Invalid Challenge Parameters
  400060, // undocumented error - https://community.cloudflare.com/t/raygun-error-cloudflare-turnstile-error-400060/651432
  /600\d{3}/, // Challenge execution failure
  /300\d{3}/, // Generic client execution error
  /11060\d{1}/, // Challenge timed out
]

function formatErrorCode(code: number | RegExp) {
  return code instanceof RegExp ? code.source : code;
}

const errorCodesPattern = CLOUDFLARE_TURNSTILE_ERROR_CODES.map(formatErrorCode).join('|');
const TURNSTILE_ERROR_MESSAGE_REGEX = new RegExp(`\\[Cloudflare Turnstile\\] Error: (${errorCodesPattern})`);
const TURNSTILE_ERROR_CODE_REGEX = new RegExp(`^(${errorCodesPattern})$`);

export const shouldIgnoreTurnstileError = (code: string): boolean => {
  return TURNSTILE_ERROR_CODE_REGEX.test(code);
};

export const ignoreErrors = [
  /Cannot find component: '(\w)*'. Make sure your component is available to render./i,
  /ChunkLoadError: Loading chunk (\d)* failed/i,
  /Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node/i,
  /Failed to fetch/i,
  /undefined is not an object \(evaluating 'r\.DomainData'\)/i,
  /You are not authorized to take this action/i,
  /An unexpected error has occurred, please try again later/i,
  /'unsafe-eval' is not an allowed source of script in the following Content Security Policy directive/i,
  /Can't find variable: _AutofillCallbackHandler/i,
  /ResizeObserver loop completed with undelivered notifications/i,
  TURNSTILE_ERROR_MESSAGE_REGEX,
];
